<template>
	<section v-if="reviewslider.length" class="main-content review-section">
		<div class="row">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 review-slider">
				<client-only>
					<carousel :per-page="1" :pagination-position="'bottom-overlay'" class="carousel">
						<slide v-for="review in reviewslider" :key="review.ID" class="review-slider-content">
							<p>"{{ review.content }}"</p>

							<div class="source">
								<span class="source-logo" :class="review.source" />
								<div>
									<strong>{{ review.firstName }}</strong>
									<span v-if="review.date">
										{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
									</span>
								</div>
							</div>
						</slide>

						<template #addons>
							<pagination />
						</template>
					</carousel>
				</client-only>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 align-center">
				<nuxt-link class="button no-fill" :to="localePath('reviews', locale)">
					{{ $t('allReviews') }}
				</nuxt-link>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviewslider } = await useWebsiteFetch('reviewslider', {
	query: { language: locale.value },
	key: `${locale.value}/reviewslider`,
});

const getReviewTotalAverage = () => {
	const sum = reviewslider.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviewslider.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviewslider.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.review-section {
	padding: 75px 0;
}

.carousel {
	padding: 25px 0;
}

.review-slider {
	padding: 0 50px 20px;
	overflow: hidden;
	max-width: 980px;
	margin: 0 auto;
	float: none;

	.review-slider-content {
		flex-direction: column;
		text-align: center;
		font-size: 22px;

		p {
			line-height: 34px;
		}

		.source {
			width: 280px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-flow: row nowrap;
			margin: 20px auto;
			gap: 15px;

			div {
				display: flex;
				flex-flow: column wrap;
				justify-content: flex-start;
				align-items: flex-start;
			}

			span {
				font-size: 16px;
				color: var(--secondary-background-color);
				margin: 0;
			}

			strong {
				margin: 0;
				font-size: 16px;
				color: var(--header-color);
			}

			.source-logo {
				margin: 0;
				width: 30px;
				height: 30px;
				background-size: 30px;

				&.tripadvisor {
					background-image: url('~/assets/images/review-logo-tripadvisor.png');
				}

				&.bookingcom {
					background-image: url('~/assets/images/review-logo-bookingcom.png');
				}

				&.zoover {
					background-image: url('~/assets/images/review-logo-zoover.png');
				}

				&.expedia {
					background-image: url('~/assets/images/review-logo-expedia.png');
				}

				&.google {
					background-image: url('~/assets/images/review-logo-google.png');
				}
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__viewport ol),
:deep(.carousel__pagination) {
	margin: 0;
	padding: 0;
}

:deep(.carousel__pagination-item) {
	margin: 0 3px;

	button {
		width: 16px;
		height: 16px;
		border: 2px solid var(--body-color);
		background: none;
		border-radius: 50%;

		&.carousel__pagination-button--active {
			background: var(--secondary-background-color);
			border-color: var(--body-color);
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;
		overflow: hidden;
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 50px 70px;
		overflow: hidden;
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 20px;
	}
}
</style>
